.chat-aside {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 300px;
  background: $classic-base-color;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 10%);
  z-index: 1000;
  transition: height 0.3s ease;
  border: solid 1px $base-shadow-color;
  color: $classic-secondary-color;

  .counter {
    background-color: $classic-secondary-color;
    color: $base-shadow-color;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 12px;
    margin-left: 8px;
    font-weight: bold;
    display: inline-block;
    min-width: 18px;
    text-align: center;
  }

  &--collapsed {
    height: 50px;
  }

  &--expanded {
    height: 80vh;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: block;
    border: 0;
    background: transparent;
    color: $classic-secondary-color;

    a {
      margin-left: 1ch;
      margin-right: 1ch;
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    background: var(--brand-color);
    color: $classic-secondary-color;
    border-radius: 8px 8px 0 0;

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    &-button {
      background: transparent;
      border: none;
      color: $classic-secondary-color;
      cursor: pointer;
      padding: 5px;

      &:hover {
        background: $inverted-text-color;
        border-radius: 4px;
      }
    }

    a {
      color: $classic-primary-color;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &__conversations {
    padding: 1rem;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    height: 15%;

    h3 {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
      color: var(--primary-text-color);
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  &__conversation {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: var(--background-color);
    }

    &--active {
      background: var(--background-color);
    }

    &-details {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &-name {
      font-weight: 500;
      color: var(--primary-text-color);
    }

    &-last-message {
      font-size: 12px;
      color: var(--secondary-text-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    height: 78%;
    overflow: auto;
    border-top: 1px solid var(--background-border-color);

    &-header {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-bottom: 1px solid var(--background-border-color);

      &-name {
        flex: 1;
        font-weight: 500;
        color: var(--primary-text-color);
      }
    }

    &-content-box {
      margin-bottom: 1rem;
    }

    &-content {
      flex: 1;
      padding: 1rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-ours {
        background-color: rgb(46, 15, 146);
        width: 70%;
        text-align: right;
        color: #fff;
        border-radius: 4rem;
        margin-left: calc(30% - 1rem);
      }

      &-other-person {
        width: 70%;
        background-color: rgb(33, 139, 184);
        color: #fff;
        border-radius: 1rem;
        padding: 1rem;
        text-align: left;
      }
    }

    &-input {
      padding: 10px;
      display: flex;
      gap: 10px;
      border-top: 1px solid var(--background-border-color);

      textarea {
        flex: 1;
        padding: 8px;
        border: 1px solid var(--background-border-color);
        border-radius: 4px;
        height: 5rem;
      }

      button {
        width: 40px;
        height: 40px;
        border: none;
        background: var(--brand-color);
        color: #fff;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: var(--brand-color-hover);
        }
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;

    &--own {
      align-self: flex-end;
      align-items: flex-end;

      .chat-aside__message-bubble {
        background: var(--brand-color);
        color: #fff;
      }
    }

    &-bubble {
      padding: 8px 12px;
      background: var(--background-color);
      border-radius: 12px;
      color: var(--primary-text-color);
      word-break: break-word;
    }

    &-time {
      font-size: 10px;
      color: var(--secondary-text-color);
      margin-top: 2px;
    }
  }

  &__close-conversation {
    background: transparent;
    border: none;
    color: var(--primary-text-color);
    cursor: pointer;
    padding: 5px;

    &:hover {
      background: var(--background-color);
      border-radius: 4px;
    }
  }

  .conversation-item {
    background-color: rgba(140, 141, 255, 40%);
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: solid 1px transparent;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: 5rem;
    word-wrap: normal;

    &:hover {
      background-color: rgba(140, 141, 255, 80%);
    }

    &.active-conversation {
      border: solid 1px #ccc;
      background-color: #13222f;
      color: #fff;
    }
  }

  .conversation-item-container {
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .chat-message__timestamp {
    font-size: 0.55rem;
    color: #ccc;
  }

  .submit-button {
    background: $classic-base-color;
    padding: 1rem;
    display: block;
    width: 100%;
  }

  .chat-aside__conversations-list {
    height: 5rem;
    display: block;
    overflow: scroll;
    padding-top: 1rem;
  }

  .chat-aside__conversations-counter {
    padding: 0.5rem 1rem;
    background: $classic-primary-color;
    color: $classic-base-color;
    margin-right: 1ch;
    border-radius: 5rem;
    margin-bottom: 0;
    width: 1rem;
    height: 1rem;
    display: inline-block;
  }

  .active-conversation-info {
    margin-left: 1ch;

    .account__avatar,
    img {
      display: inline-block;
      float: left;
      width: 24px;
      margin-right: 1ch;
    }
  }

  .chat-aside__messages-header {
    button {
      background: transparent;
      border: 0;
      padding: 0.5rem;
      float: right;
      margin-bottom: 0.5rem;
    }
  }

  #compose_direct_chat_message {
    background: #0a0a0a;
    color: $classic-primary-color;
    width: 80%;
  }

  .submit-button {
    position: relative;
    width: 20%;
    display: inline-block;
  }

  #chat_aside_messages_content {
    padding: 0 1rem;
  }
}
