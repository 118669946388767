.status__wrapper {
  .status {
    border-color: #6c6ea8 !important;
    border-left: 1px !important;
    border-right: 1px !important;
  }
}

.tabs-bar__wrapper {
  background: #272532 !important;
}

.columns-area__panels__main,
.notification__filter-bar,
.notification-ungrouped,
.column-header__title,
.column-header__buttons,
.compose-form__highlightable,
.compose-form__scrollable,
.search__input,
.column-header__buttons,
.column-header__title,
.notification-ungrouped,
.detailed-status__wrapper,
.search .search__input,
.compose-form__highlightable,
.compose-form .autosuggest-textarea__textarea,
.compose-form .spoiler-input__input {
  background: #191921 !important;
}

// bordures entourées
.column-inline-form,
.column-subheading,
.search__input,
.compose-form__highlightable,
.item-list,
.column-header,
.account__section-headline {
  border: solid 1px #6c6ea8 !important;
}

.current-mammouth {
  img {
    width: 285px;
    max-height: 400px;
  }
}

.navigation-panel {
  .navigation-panel__logo {
    width: 180px !important;
    height: auto !important;
    margin-bottom: 2rem !important;
    margin-top: 1rem;
  }

  .list-panel__link {
    margin-left: 1rem;
    display: block;
    width: 200px;
  }

  .column-link svg {
    margin-right: 1rem;
  }

  .column-link {
    padding-left: 0;

    &.navigation-panel__link--conversations,
    &.navigation-panel__link--lists,
    &.navigation-panel__link--preferences {
      margin-top: 1rem !important;
    }
  }
}

.navigation-panel__menu {
  a {
    &:visited {
      color: #fff !important;
    }

    svg {
      width: 1.2rem !important;
    }

    &:hover,
    &:focus {
      color: #6364ff !important;

      svg {
        fill: rgb(139.8, 140.53, 255) !important;
      }
    }
  }
}

.account__section-headline {
  .active {
    background: #13222f !important;
  }
}

.list-panel__link {
  display: block;
  color: $classic-primary-color;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 1ch;
  }

  span {
    margin-top: -1.6rem;
    display: block;
    margin-left: 2rem;
  }
}

.item-list {
  padding-top: 1rem;
}

.muted {
  .status__content__text p {
    color: white !important;
  }

  .emojione {
    opacity: 1;
  }
}

.status__info {
  padding-bottom: 1.5rem;
}

.status__content__text,
.conversation .conversation__content .status__content {
  min-height: 6rem;
}

.notif-link-panel {
  padding-top: 2rem;
}

.status__display-name {
  color: $classic-primary-color;
}
